section#admin-page {
    width: 80%;
    margin-inline: auto;
    height: 100vh;
    position: relative;
}

section#admin-page h1 {
    margin: 0;
    padding-top: 5rem;
}

/* LOGIN FORM */

section#admin-page .admin-login form {
    width: 15vw;
    height: 20vh;
    margin-inline: auto;
    margin-top: 20vh;
    padding: 3em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    border: 1px solid var(--text-color);
    position: relative;
}

section#admin-page .admin-login form p {
    position: absolute;
    top: -2em;
    left: 1em;
    background-color: var(--primary-color);
    width: 8em;
    text-align: center;
}

section#admin-page .admin-login form input {
    background-color: var(--secondary-color);
    border: none;
    padding: .5em;
    color: var(--text-color);
}

section#admin-page .admin-login form input:focus {
    outline: 1px solid var(--accent-color);
}

section#admin-page .admin-login form div {
    position: absolute;
    bottom: -1em;
    width: 12em;
    left: calc(50% - (12em / 2));
    display: flex;
    justify-content: center;
    background-color: var(--primary-color);
}

section#admin-page .admin-login form button {
    background-color: var(--accent-color);
    color: var(--text-color);
    padding: .25em;
    width: 10em;
    cursor: pointer;
}

/* MAIN */

section#admin-page .admin-main {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 5em;
}

section#admin-page .admin-main .admin-projects,
section#admin-page .admin-main .admin-messages {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
    width: calc(50% - 6em - 2px - 1em);
    padding: 3em;
    border: 1px solid var(--accent-color);
    position: relative;
}

section#admin-page .admin-main .admin-section-header,
section#admin-page .admin-main .admin-section-more,
section#admin-page .admin-main .admin-section-button {
    position: absolute;
    background-color: var(--primary-color);
    width: 10em;
    text-align: center;
}

section#admin-page .admin-main .admin-section-header {
    top: -1.75em;
}

section#admin-page .admin-main .admin-section-more {
    bottom: -1.5em;
}

section#admin-page .admin-main .admin-section-button {
    bottom: -1em;
    right: 2.5em;
}

section#admin-page .admin-main .admin-section-button button {
    background-color: var(--accent-color);
    color: var(--text-color);
    padding: .5em 1em;
    cursor: pointer;
}

section#admin-page .admin-main .admin-section-button button:hover {
    background-color: var(--text-color);
    color: var(--primary-color);
}

section#admin-page .admin-main .admin-projects .project-container,
section#admin-page .admin-main .admin-messages .message-container {
    border-bottom: 1px solid var(--text-color);
    padding: .25em 1em;
    transition-duration: .5s;
    cursor: pointer;
    position: relative;
}

section#admin-page .admin-main .admin-projects .project-container:hover,
section#admin-page .admin-main .admin-messages .message-container:hover {
    background-color: var(--accent-color);
    border-bottom: 1px solid var(--secondary-color);
}

section#admin-page .admin-main .admin-projects .project-container h2,
section#admin-page .admin-main .admin-messages .message-container h2 {
    font-size: 100%;
}

section#admin-page .admin-main .admin-projects .project-container p,
section#admin-page .admin-main .admin-messages .message-container p {
    font-size: 80%;
}

section#admin-page .admin-main .admin-projects .project-container .project-actions,
section#admin-page .admin-main .admin-messages .message-container .message-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    position: absolute;
    right: 1em;
    bottom: 0;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity .5s, visibility .5s;
}

section#admin-page .admin-main .admin-projects .project-container:hover .project-actions,
section#admin-page .admin-main .admin-messages .message-container:hover .message-actions {
    visibility: visible;
    opacity: 1;
}

section#admin-page .admin-main .admin-projects .project-container .project-actions svg,
section#admin-page .admin-main .admin-messages .message-container .message-actions svg {
    height: 1.25em;
    width: 1.25em;
    transition: transform .5s;
}

section#admin-page .admin-main .admin-projects .project-container .project-actions svg:hover,
section#admin-page .admin-main .admin-messages .message-container .message-actions svg:hover {
    transform: scale(1.2);
}


/* logout */

section#admin-page .admin-logout {
    position: absolute;
    top: 5.5rem;
    right: 0;
    padding: .5rem 3rem;
    background-color: var(--accent-color);
    color: var(--text-color);
    cursor: pointer;
}

section#admin-page .admin-logout:hover {
    background-color: var(--text-color);
    color: var(--primary-color);
}